import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Heading from "@components/elements/heading"
import { ReactComponent as KlipsLogo } from "@images/comparison/klips.svg"
import Span from "@components/elements/span"
import { useTheme } from "styled-components"
import Div from "@components/elements/div"
import Grid from "@components/elements/grid"
import Paragraph from "@components/elements/paragraph"
import Button from "@components/elements/button"
import { KlipsEOPCta } from "@components/cta/eop/klips"
import Image from "@components/image"
import { processImages } from "@utils/process-images"
import Layout from "@components/layout"
import { HighGradient } from "@components/common/gradient"
import { useGradient } from "@hooks/use-gradient"
import Flex from "@components/elements/flex"
import { TestimonialUser } from "@components/common/testimonial"
import styled from "styled-components"
import theme from "../../styles/theme"

const GlassyDiv = styled.div`
  background: radial-gradient(
    90.16% 143.01% at 15.32% 21.04%,
    rgba(165, 239, 255, 0.2) 0%,
    rgba(236, 183, 210, 0.0447917) 77.08%,
    rgba(70, 144, 213, 0) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-radius: 1.5rem;
  box-shadow: ${theme.shadow.glassy};
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 2px solid rgba(255, 255, 255, 0.59);
`

const KlipsForPartners = ({ data }) => {
  const { color, shadow } = useTheme()
  const images = processImages(data.images.edges)
  const { gradient } = useGradient(true)

  return (
    <Layout
      title="Klips for Client Reporting"
      description={`Manage your clients with Klips. Whether you’re looking to automate reporting, centralize your customers’ data to provide better insights, or scale your business with new revenue streams, we’re here for you.`}
      fullWidth
    >
      <Div position="relative" overflow="clip visible">
        <HighGradient
          style={{ minWidth: "2500px" }}
          top="48rem"
          src={gradient.cdn}
          loading="lazy"
        />
        <Flex
          margin="4rem auto"
          alignItems="center"
          textContainer
          center
          position="relative"
        >
          <KlipsLogo height="60" />
          <Heading
            margin="2rem auto 1rem"
            fontSize="3.6rem"
            color={color.blue600}
          >
            Impress your clients with
            <br />
            <Span color={color.blue400}>beautiful custom dashboards</Span>
          </Heading>
          <Paragraph
            margin="0 auto 2rem"
            fontWeight={600}
            lineHeight={1.5}
            color={color.blue600}
          >
            With powerful client management, pipeline automation, and
            pixel-perfect dashboards and reports, your clients will love what
            you love building in Klipfolio Klips.
          </Paragraph>
          <Button.Klips />
        </Flex>
        <Div margin="4rem auto 0" position="relative" container>
          <GlassyDiv padding="1rem">
            <Image file={images["6acae780-37eb-4243-80c9-01566e47e7ec"]} />
          </GlassyDiv>
          <Div width="1290px" hideOnMd left={"-5%"} position="absolute" top={0}>
            <Image file={images["b9e8eaae-d694-4bdf-835d-86daf10be79f"]} />
          </Div>
        </Div>
      </Div>
      <Grid
        container
        margin="12rem auto"
        marginMd="rem auto"
        marginSm="4rem auto"
        alignItems="center"
        gap="8rem"
        gapMd="4rem"
        gapSm="2rem"
        columns="2fr 3fr"
        columnsMd="1fr"
      >
        <Image
          file={images["c0bd6f0a-fc06-4d5c-8053-6b4acd1d86c4"]}
          style={{ maxHeight: "340px" }}
          objectFit="contain"
        />
        <Div>
          <Heading
            color={color.blue600}
            margin="0 0 1.5rem"
            fontSize="3rem"
            as="h2"
          >
            Offer your clients the industry’s highest-rated agency reporting
            solution
          </Heading>
          <Paragraph color={color.blue700}>
            By partnering with Klipfolio, you can start giving your customers
            access to the world’s best dashboard platform without breaking a
            sweat (or the bank).
          </Paragraph>
        </Div>
      </Grid>
      <Flex
        margin="8rem auto 0"
        gap="12rem"
        gapMd="8rem"
        gapSm="6rem"
        container
      >
        <Div>
          <Image file={images["e7a24272-ca51-418e-b40d-e37e1cf5878f"]} />
          <Div margin="2rem auto 0" textContainer>
            <Span
              color={color.blue400}
              fontSize="1.3rem"
              fontWeight={600}
              margin="0 0 1rem"
            >
              Connect all your clients’ data
            </Span>
            <Heading
              as="h2"
              margin="0 0 1.5rem"
              fontSize="3rem"
              color={color.blue600}
            >
              Everything in one place
            </Heading>
            <Paragraph color={color.blue600} margin="0 0 2rem">
              With over 130 data service integrations, you can count on Klips to
              get your clients’ data into dashboards. Pull in data that’s stored
              on premise or in the cloud, upload spreadsheets, write custom SQL
              queries, or email data automatically from 3rd-party services.
              Excel-like formulas and functions help you transform your data,
              perform calculations, add logic and client-level personalization.
              Real-time, raw data will update your clients’ dashboards right in
              front of their eyes.
            </Paragraph>
            <Button.Klips />
          </Div>
        </Div>
        <Div>
          <Div hideOnMd container position="relative" margin="0 auto 6rem">
            <Image
              radius={1}
              borderStyle={{ boxShadow: shadow.dramatic, zIndex: 0 }}
              style={{ margin: "0 auto" }}
              width={750}
              file={images["425806f7-396c-452d-8b6b-72237b24223a"]}
            />
            <Div position="absolute" top="90px" left="25px">
              <svg
                width="200"
                height="118"
                viewBox="0 0 200 118"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 111V52C6 24.3858 28.3858 2 56 2H200"
                  stroke="#e0f8ff"
                  strokeWidth="4"
                ></path>
                <circle cx="6" cy="112" r="6" fill="#e0f8ff"></circle>
              </svg>
            </Div>
            <Div position="absolute" top="220px" left="15px" width="150px">
              <svg
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  marginBottom: "1rem",
                  width: "35px",
                  display: "block",
                }}
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 22C6.49 22 2 17.51 2 12S6.49 2 12 2s10 4.04 10 9c0 3.31-2.69 6-6 6h-1.77c-.28 0-.5.22-.5.5 0 .12.05.23.13.33.41.47.64 1.06.64 1.67A2.5 2.5 0 0112 22zm0-18c-4.41 0-8 3.59-8 8s3.59 8 8 8c.28 0 .5-.22.5-.5a.54.54 0 00-.14-.35c-.41-.46-.63-1.05-.63-1.65a2.5 2.5 0 012.5-2.5H16c2.21 0 4-1.79 4-4 0-3.86-3.59-7-8-7zm-5.5 9a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM11 7.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM14.5 9a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm4.5 2.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
                  fill="#01394f"
                ></path>
              </svg>
              <span>Customize the dashboard theme with CSS</span>
            </Div>
            <Div position="absolute" top="-81px" right="150px">
              <svg
                width="206"
                height="115"
                viewBox="0 0 206 115"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 115V56C2 28.3858 24.3858 6 52 6H199"
                  stroke="#e0f8ff"
                  strokeWidth="4"
                ></path>
                <circle cx="200" cy="6" r="6" fill="#e0f8ff"></circle>
              </svg>
            </Div>
            <Div position="absolute" bottom="-65px" left="10px" width="150px">
              <svg
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                style={{
                  marginBottom: "1rem",
                  width: "35px",
                  display: "block",
                }}
              >
                <path
                  d="M4 11h4v8h2V4h4v15h2v-7h4v7h2v1H2v-1h2v-8z"
                  fill="#01394f"
                ></path>
              </svg>
              <span>
                Add a pre-built data visualization from the Klip Gallery
              </span>
            </Div>
            <Div position="absolute" bottom="82px" right="68px" width="166px">
              <svg
                width="166"
                height="89"
                viewBox="0 0 166 89"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M160 11V37C160 64.6142 137.614 87 110 87H5.33462e-06"
                  stroke="#e0f8ff"
                  strokeWidth="4"
                ></path>
                <circle cx="160" cy="6" r="6" fill="#e0f8ff"></circle>
              </svg>
            </Div>
            <Div position="absolute" bottom="190px" right="0" width="150px">
              <svg
                width="24"
                height="24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  marginBottom: "1rem",
                  width: "35px",
                  display: "block",
                }}
              >
                <path
                  d="M11.678 4.263L16 8.586l3.293-3.293 1.414 1.414L16 11.414l-3.677-3.677-3.607 8.115-4.473 1.118-.485-1.94 3.527-.882 4.393-9.885zM22 19V20H2v-1.001h20z"
                  fill="#01394f"
                ></path>
              </svg>
              <span>Choose a visualization type</span>
            </Div>
            <Div position="absolute" bottom="-35px" left="160px" width="133px">
              <svg
                width="133"
                height="82"
                viewBox="0 0 133 82"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M131 0V26C131 53.6142 108.614 76 81 76H6"
                  stroke="#e0f8ff"
                  strokeWidth="4"
                ></path>
                <circle cx="6" cy="76" r="6" fill="#e0f8ff"></circle>
              </svg>
            </Div>
            <Div position="absolute" top="-90px" right="-10px" width="150px">
              <svg
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                style={{
                  marginBottom: "1rem",
                  width: "35px",
                  display: "block",
                }}
              >
                <path
                  d="M17.55 3.977l-4.54 7.906v5.93l-2.018.989v-6.919l-4.54-7.906H17.55zM21 2H3l1.692 2.946L8.973 12.4V22l2.92-1.43 2.018-.988 1.116-.547v-6.634l4.28-7.455L21 2z"
                  fill="#01394f"
                ></path>
              </svg>
              <span>Apply filters</span>
            </Div>
          </Div>
          <Div showOnMd container margin="0 auto 2rem">
            <Image
              radius={1}
              borderStyle={{ boxShadow: shadow.default, zIndex: 0 }}
              file={images["425806f7-396c-452d-8b6b-72237b24223a"]}
            />
          </Div>
          <Div textContainer>
            <Span
              color={color.blue400}
              fontSize="1.3rem"
              fontWeight={600}
              margin="0 0 1rem"
            >
              Build it all
            </Span>
            <Heading
              as="h2"
              margin="0 0 1.5rem"
              fontSize="3rem"
              color={color.blue600}
            >
              Highly customizable
              <br />
              dashboards and visualizations
            </Heading>
            <Paragraph color={color.blue600} margin="0 0 2rem">
              Every client has different needs. Klips allows you to build
              virtually any visualization you want and control the look and feel
              of your dashboards with custom themes. Modify visualization
              properties in the Klip Editor or dig in and build powerful,
              one-of-a-kind visualizations using the HTML template component,
              with JavaScript and inline CSS.
            </Paragraph>
            <Button.Klips />
          </Div>
        </Div>
        <Div>
          <Image file={images["dd3be671-7f89-4be5-892c-7f8aaff8f7f4"]} />
          <Div textContainer>
            <Span
              color={color.blue400}
              fontSize="1.3rem"
              fontWeight={600}
              margin="0 0 1rem"
            >
              Reporting automation and templates
            </Span>
            <Heading
              as="h2"
              margin="0 0 1.5rem"
              fontSize="3rem"
              color={color.blue600}
            >
              Serve more clients and scale fast
            </Heading>
            <Paragraph color={color.blue600} margin="0 0 2rem">
              Your time is valuable. Skip the manual effort involved in serving
              multiple clients. Instead, use templates and advanced reporting
              automation to save hundreds of hours. Build dashboard templates
              once and copy them into individual customer accounts. With the
              Klips report engine, you can set up scheduled email snapshots or
              reports. Say goodbye to manual effort and hello to automation!
            </Paragraph>
            <Button.Klips />
          </Div>
        </Div>

        <Div>
          <Heading
            as="h2"
            maxWidth={"600px"}
            margin="0 auto 4rem"
            fontSize={"3rem"}
            center
            color={color.blue600}
          >
            Beyond Dashboards
            <br />
            <Span color={color.blue400}>Klips Features</Span>
          </Heading>
          <Flex gap="4rem">
            <Grid columns="1fr 2fr" gap="4rem" gapMd="2rem" columnsMd="1fr">
              <Div>
                <Heading as="h3" color={color.blue600} margin="0 0 1rem">
                  Client Management
                </Heading>
                <Paragraph color={color.blue700}>
                  Whether you manage a few client accounts, or hundreds,
                  Klipfolio’s Client Management platform will scale with your
                  business.
                </Paragraph>
              </Div>
              <Grid gap="2rem" columns="1fr 1fr" columnsMd="1fr">
                <Div>
                  <Image
                    width={50}
                    height={50}
                    file={images["14538f12-3166-407c-afd5-049d7f5bf134"]}
                  />
                  <Heading as="h4" color={color.blue600} margin="1rem 0 .5rem">
                    Manage multiple accounts
                  </Heading>
                  <Paragraph color={color.blue700}>
                    Create and manage hundreds of new clients from your partner
                    account with the click of a button.
                  </Paragraph>
                </Div>
                <Div>
                  <Image
                    width={50}
                    height={50}
                    file={images["09d1c3a7-9383-4d40-b4fc-94730b65ae30"]}
                  />
                  <Heading as="h4" color={color.blue600} margin="1rem 0 .5rem">
                    Standard or unique, you choose
                  </Heading>
                  <Paragraph color={color.blue700}>
                    Import data visualizations and sources. Create dynamic
                    dashboards that are standard or exclusive to each client
                    with the admin sign-in option.
                  </Paragraph>
                </Div>
                <Div>
                  <Image
                    width={50}
                    height={50}
                    file={images["cdd2ea85-964c-4213-8025-4fefe30c7084"]}
                  />
                  <Heading as="h4" color={color.blue600} margin="1rem 0 .5rem">
                    Free accounts for your clients
                  </Heading>
                  <Paragraph color={color.blue700}>
                    Show your clients the value of dashboards before they commit
                    with free trial accounts.
                  </Paragraph>
                </Div>
                <Div>
                  <Image
                    width={50}
                    height={50}
                    file={images["f89a5954-74ac-4ce2-91d9-925fe6310934"]}
                  />
                  <Heading as="h4" color={color.blue600} margin="1rem 0 .5rem">
                    Customized billing options
                  </Heading>
                  <Paragraph color={color.blue700}>
                    Bill your clients yourself or choose to save time and effort
                    with Klipfolio’s managed client billing.
                  </Paragraph>
                </Div>
              </Grid>
            </Grid>
            <Grid columns="1fr 2fr" gap="4rem" columnsMd="1fr">
              <Div>
                <Heading as="h3" color={color.blue600} margin="0 0 1rem">
                  Customization and Automation
                </Heading>
                <Paragraph color={color.blue700}>
                  Enjoy the freedom and flexibility of customization. Start with
                  templates for your typical clients or create unique content
                  when needed..
                </Paragraph>
              </Div>
              <Grid gap="2rem" columns="1fr 1fr" columnsMd="1fr">
                <Div>
                  <Image
                    width={50}
                    height={50}
                    file={images["7bdbb9a1-8604-4d68-954a-a8fecd3058c3"]}
                  />
                  <Heading as="h4" color={color.blue600} margin="1rem 0 .5rem">
                    Personalize your dashboard
                  </Heading>
                  <Paragraph color={color.blue700}>
                    Clone dashboard templates to avoid starting from scratch.
                    Take customization to a new level by creating unique,
                    personalized themes with CSS and Javascript.
                  </Paragraph>
                </Div>
                <Div>
                  <Image
                    width={50}
                    height={50}
                    file={images["46b8387a-2011-41e2-ae51-7fc34450ff3f"]}
                  />
                  <Heading as="h4" color={color.blue600} margin="1rem 0 .5rem">
                    White-labelling
                  </Heading>
                  <Paragraph color={color.blue700}>
                    Our white-labelling feature gives you the opportunity to
                    replace Klipfolio branding with your own. Change key
                    application terminology, colours, logos and more.
                  </Paragraph>
                </Div>
                <Div>
                  <Image
                    width={50}
                    height={50}
                    file={images["f5fad530-073d-48bd-a5a6-e835c366e647"]}
                  />
                  <Heading as="h4" color={color.blue600} margin="1rem 0 .5rem">
                    Domain aliasing
                  </Heading>
                  <Paragraph color={color.blue700}>
                    Don’t want your clients logging into their dashboards at
                    app.klipfolio.com? No problem. Domain aliasing allows you to
                    direct your clients to a domain you specify to log in.
                  </Paragraph>
                </Div>
                <Div>
                  <Image
                    width={50}
                    height={50}
                    file={images["ff672898-5bc9-42f8-8cea-a93f67f5be19"]}
                  />
                  <Heading as="h4" color={color.blue600} margin="1rem 0 .5rem">
                    Automated processes
                  </Heading>
                  <Paragraph color={color.blue700}>
                    With a little API work and some script writing, you can
                    streamline and automate adding users, sharing content, and
                    creating data sources.
                  </Paragraph>
                </Div>
              </Grid>
            </Grid>
            <Grid columns="1fr 2fr" gap="4rem" columnsMd="1fr">
              <Div>
                <Heading as="h3" color={color.blue600} margin="0 0 1rem">
                  Sharing and Publishing
                </Heading>
                <Paragraph color={color.blue700}>
                  Whether you want to give your client on-demand access to the
                  dashboards you create, or automate dashboard reporting, or
                  send out a client report on an ad hoc basis, Klips has an
                  option that will make sharing with clients easy.
                </Paragraph>
              </Div>
              <Grid gap="2rem" columns="1fr 1fr">
                <Div>
                  <Image
                    width={50}
                    height={50}
                    file={images["0b347b47-dcef-4283-bbc5-c4035ded6eb0"]}
                  />
                  <Heading as="h4" color={color.blue600} margin="1rem 0 .5rem">
                    User access controls
                  </Heading>
                  <Paragraph color={color.blue700}>
                    As the administrator of a client account, you can grant
                    access to the account, setting different rights and
                    privileges for each user.
                  </Paragraph>
                </Div>
                <Div>
                  <Image
                    width={50}
                    height={50}
                    file={images["8e6dc542-e54a-4d01-ba13-af3f009beb75"]}
                  />
                  <Heading as="h4" color={color.blue600} margin="1rem 0 .5rem">
                    Published Links
                  </Heading>
                  <Paragraph color={color.blue700}>
                    Share dashboards with clients using Published Links, which
                    are URLs that can be shared with or without a password.
                  </Paragraph>
                </Div>
                <Div>
                  <Image
                    width={50}
                    height={50}
                    file={images["f89a5954-74ac-4ce2-91d9-925fe6310934"]}
                  />
                  <Heading as="h4" color={color.blue600} margin="1rem 0 .5rem">
                    Email reports
                  </Heading>
                  <Paragraph color={color.blue700}>
                    Schedule regular email snapshots of individual data
                    visualizations or entire dashboards to be sent to specific
                    people, at specific times.
                  </Paragraph>
                </Div>
                <Div>
                  <Image
                    width={50}
                    height={50}
                    file={images["ad110032-acaa-4d31-acd4-a8d5bb6edc87"]}
                  />
                  <Heading as="h4" color={color.blue600} margin="1rem 0 .5rem">
                    Embedding
                  </Heading>
                  <Paragraph color={color.blue700}>
                    Seamlessly and easily embed data visualizations into HTML
                    pages or host applications.
                  </Paragraph>
                </Div>
              </Grid>
            </Grid>
          </Flex>
        </Div>
        <Flex gap="4rem" margin="0 auto 8rem" textContainer>
          <Heading
            as="h2"
            maxWidth={"600px"}
            margin="0 auto 0"
            fontSize={"3rem"}
            center
            color={color.blue600}
          >
            See what our customers are saying about Klips
          </Heading>
          <Grid columns="34px 1fr" gap="2rem">
            <Image
              style={{ marginTop: "8px" }}
              width={34}
              height={23}
              file={images["90d8f385-95f8-4c5e-8a89-2cd3a4300fd2"]}
            />
            <Div>
              <Paragraph
                margin="0 0 2rem"
                color={color.blue600}
                fontWeight={700}
              >
                I build dashboards for clients across many industries and it is
                by far the best tool I’ve come across for building KPI reports
                that are used daily and motivate teams to make progress on
                business goals.
              </Paragraph>
              <TestimonialUser
                color={color.blue600}
                name="Andrew W."
                title="Director at IT MOOTI"
                image={images["97ed0509-9c4c-4fca-bd26-0421e04b9c8c"]}
                multiline
              />
            </Div>
          </Grid>
          <Grid columns="34px 1fr" gap="2rem">
            <Image
              style={{ marginTop: "8px" }}
              width={34}
              height={23}
              file={images["90d8f385-95f8-4c5e-8a89-2cd3a4300fd2"]}
            />
            <Div>
              <Paragraph
                margin="0 0 2rem"
                color={color.blue600}
                fontWeight={700}
              >
                I like how much you can customize the look and feel of your
                dashboard. Klipfolio connects all of our disparate systems
                within our HR department. Seeing survey, HRIS, and ATS data in
                one dashboard is really cool.
              </Paragraph>
              <TestimonialUser
                color={color.blue600}
                name="Carolyn S."
                title="People Insights Manager at formstack.com"
                image={images["9b22b67a-de5c-40b7-95ce-478d0ceb98bd"]}
                multiline
              />
            </Div>
          </Grid>
          <Grid columns="34px 1fr" gap="2rem">
            <Image
              style={{ marginTop: "8px" }}
              width={34}
              height={23}
              file={images["90d8f385-95f8-4c5e-8a89-2cd3a4300fd2"]}
            />
            <Div>
              <Paragraph
                margin="0 0 2rem"
                color={color.blue600}
                fontWeight={700}
              >
                We upload daily data about our business and it’s really helpful
                to get insights and monitor revenue in real-time.
              </Paragraph>
              <TestimonialUser
                color={color.blue600}
                name="Sebastián P."
                title="CPO at E-Planning.net"
                image={images["5feeb97c-ba2e-4b10-8ef9-d24a93d0e1f0"]}
                multiline
              />
            </Div>
          </Grid>
        </Flex>
      </Flex>
      <KlipsEOPCta />
    </Layout>
  )
}

KlipsForPartners.propTypes = {
  data: PropTypes.object.isRequired,
}

export default KlipsForPartners

export const pageQuery = graphql`
  query KlipsForPartners {
    images: allDirectusFiles(
      filter: {
        directus_id: {
          in: [
            "6acae780-37eb-4243-80c9-01566e47e7ec"
            "b9e8eaae-d694-4bdf-835d-86daf10be79f"
            "c0bd6f0a-fc06-4d5c-8053-6b4acd1d86c4"
            "e7a24272-ca51-418e-b40d-e37e1cf5878f"
            "425806f7-396c-452d-8b6b-72237b24223a"
            "dd3be671-7f89-4be5-892c-7f8aaff8f7f4"
            "14538f12-3166-407c-afd5-049d7f5bf134"
            "09d1c3a7-9383-4d40-b4fc-94730b65ae30"
            "cdd2ea85-964c-4213-8025-4fefe30c7084"
            "7bdbb9a1-8604-4d68-954a-a8fecd3058c3"
            "46b8387a-2011-41e2-ae51-7fc34450ff3f"
            "f5fad530-073d-48bd-a5a6-e835c366e647"
            "ff672898-5bc9-42f8-8cea-a93f67f5be19"
            "0b347b47-dcef-4283-bbc5-c4035ded6eb0"
            "8e6dc542-e54a-4d01-ba13-af3f009beb75"
            "f89a5954-74ac-4ce2-91d9-925fe6310934"
            "ad110032-acaa-4d31-acd4-a8d5bb6edc87"
            "97ed0509-9c4c-4fca-bd26-0421e04b9c8c"
            "9b22b67a-de5c-40b7-95ce-478d0ceb98bd"
            "5feeb97c-ba2e-4b10-8ef9-d24a93d0e1f0"
            "90d8f385-95f8-4c5e-8a89-2cd3a4300fd2"
          ]
        }
      }
    ) {
      edges {
        node {
          ...imageData
        }
      }
    }
  }
`
